import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    InputBase,
    Stack,
    Typography,
    // useMediaQuery,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import BG from '../assets/car.gif';
// import back from '../assets/BG.png';
import Vip from '../assets/vip1.png';
import caricon from '../assets/caricon.png';
import Navbar from '../components/Navbar';
import { toast } from 'react-toastify';
import axios from 'axios';

const Home = ({ toggleMode }) => {
    const [value, setValue] = useState([]);
    const [addimg, setAddimg] = useState();
    // const matches1 = useMediaQuery('(max-width:600px)');""
    // const matches = useMediaQuery('(max-width:720px)');
    const Store = async () => {
        if (value[1] === 'x' && value[0] === '0' && value.length === 42) {
            await axios
                .post('/Database', { value })
                .then(() => toast.success('Address Whitelisted'))
                .catch(() => toast.error('Server Error'));
            setValue([]);
        } else {
            toast.error('Invalid Address');
        }
    };
    useEffect(() => {
        setAddimg(BG + '?a=' + Math.random());
        return () => {
            setAddimg();
        };
    }, []);

    return (
        <>
            <Box
                sx={{
                    backgroundImage: addimg && { xs: '', sm: `url(${addimg})` },
                    backgroundPosition: 'center 25%',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    height: { xs: '100%', sm: '100%', md: '100vh' },
                    px: { xs: 1, sm: 2 },
                    // my: { xs: 1, sm: 0, md: 5 },
                }}
            >
                <Navbar toggleMode={toggleMode} />

                <Stack
                    direction="column"
                    alignItems="space-between"
                    height={{ xs: '100%', sm: '100vh', md: '100vh' }}
                    width={'100%'}
                    py={{ xs: 2, sm: 3, md: 4 }}
                >
                    <Container maxWidth="xl">
                        <Box>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent={'center'}
                                mt={{ xs: 7, sm: 5, md: -1 }}
                                gap={1}
                            >
                                <Box width={{ xs: '45px', sm: '50px', md: '50px' }}>
                                    <img src={Vip} alt="" width={'100%'} />
                                </Box>
                                {/* <Typography
                                    sx={{
                                        fontFamily: 'Roboto',
                                        fontWeight: '700',
                                        fontSize: { xs: '16px', sm: '20px', md: '25px' },
                                        lineHeight: '25px',
                                        textAlign: 'center',
                                        letterSpacing: '0em',
                                        textTransform: 'capitalize',
                                        background:
                                            'linear-gradient(180deg, #E28A02 0%, #F8EF7A 48.96%, #E28A02 95.83%)',
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        backgroundClip: 'text',
                                        textFillColor: 'transparent',
                                    }}
                                >
                                    The black lambo club
                                </Typography> */}
                            </Stack>
                            <Box
                                sx={{
                                    pt: { xs: 1, sm: 2, md: 2 },
                                    fontSize: { xs: '15px', sm: '20px', md: '28px' },
                                    fontFamily: 'Roboto',
                                    fontWeight: '700',
                                    textTransform: 'upperCase',
                                    lineHeight: '22px',
                                    textAlign: 'center',
                                    letterSpacing: '0em',
                                    color: '#ffffff',
                                }}
                            >
                                Join the most exclusive crypto club.
                            </Box>
                        </Box>
                    </Container>
                    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                        <img src={addimg} alt="" width="100%" />
                    </Box>
                    <Box mt={{ xs: -8, sm: 'auto', md: 'auto' }}>
                        <Container maxWidth="xl">
                            <Grid
                                container
                                spacing={{ xs: 3, md: 1 }}
                                direction={{
                                    xs: 'column-reverse',
                                    sm: 'column-reverse',
                                    md: 'row',
                                }}
                                alignItems="center"
                                justifyContent={'center'}
                            >
                                <Grid item xs={12} sm={12} md={3}>
                                    <Box
                                        item
                                        mx={{ xs: 'auto', sm: 'auto', md: '0' }}
                                        width={{ xs: '25px', sm: '30px', md: '35px' }}
                                    >
                                        <img src={caricon} alt="" width={'100%'} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Stack
                                        direction={{ xs: 'row', sm: 'row' }}
                                        alignItems={'center'}
                                        gap={{ xs: '5px', sm: 1, md: 3 }}
                                        sx={{
                                            py: 2,
                                            px: 2,
                                            bgcolor: '#000000',
                                            borderRadius: '20px',
                                            boxShadow: '0px 0px 100px 5px  rgb(250 250 250 / 0.2)',
                                        }}
                                    >
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            width="100%"
                                            gap={{ xs: '5px', sm: '10px', md: 1 }}
                                        >
                                            <CheckCircleIcon
                                                sx={{
                                                    fontSize: {
                                                        xs: '14px',
                                                        sm: '17px',
                                                        md: '20px',
                                                    },
                                                    color:
                                                        value.length === 42 &&
                                                        value[0] === '0' &&
                                                        value[1] === 'x'
                                                            ? 'green'
                                                            : 'gray',
                                                }}
                                            />
                                            <InputBase
                                                placeholder="Enter your wallet address "
                                                sx={{
                                                    color: '#fff',
                                                    width: '100%',
                                                    pr: { xs: 0, sm: 1, md: 3 },
                                                    fontSize: {
                                                        xs: '12px',
                                                        sm: '16px',
                                                        md: '18px',
                                                    },
                                                    'input::placeholder': {
                                                        fontSize: {
                                                            xs: '12px',
                                                            sm: '14px',
                                                            md: '17px',
                                                        },
                                                    },
                                                }}
                                                value={value}
                                                onChange={(e) => setValue(e.target.value)}
                                            />
                                        </Stack>
                                        <Button
                                            onClick={() => Store()}
                                            sx={{
                                                textTransform: 'capitalize',
                                                width: { xs: '140px', sm: '150px', md: '200px' },
                                                fontSize: {
                                                    xs: '12px',
                                                    sm: '16px',
                                                    md: '19px',
                                                },
                                                fontWeight: '400',
                                                color: '#fff',
                                            }}
                                        >
                                            Whitelist ME
                                        </Button>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3}>
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Poppins',
                                                fontSize: { xs: '12px', sm: '14px', md: '15px' },
                                                fontWeight: '500',
                                                lineHeight: '22px',
                                                textAlign: { xs: 'center', md: 'right' },
                                                letterSpacing: '0em',
                                                // textTransform: 'lowercase',
                                                background:
                                                    'linear-gradient(180deg, #E28A02 0%, #F8EF7A 48.96%, #E28A02 95.83%)',
                                                WebkitBackgroundClip: 'text',
                                                WebkitTextFillColor: 'transparent',
                                                backgroundClip: 'text',
                                                textFillColor: 'transparent',
                                            }}
                                        >
                                            Ever wanted to own a lambo?
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Poppins',
                                                fontSize: { xs: '16px', sm: '18px', md: '20px' },
                                                fontWeight: '500',
                                                lineHeight: '21px',
                                                textAlign: { xs: 'center', md: 'right' },
                                                letterSpacing: '0em',
                                                textTransform: 'upperCase',
                                            }}
                                        >
                                            Now you have a chance
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </Stack>
            </Box>
        </>
    );
};

export default Home;
