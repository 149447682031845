import React, { useState } from 'react';
import { Box, Container, Stack, useMediaQuery } from '@mui/material';

import Discord from '../assets/discord.svg';
import Telegram from '../assets/telegram.svg';
import Twitter from '../assets/twitter.svg';
import Logo from '../assets/Logo.png';
import LogoName from '../assets/LogoName.png';

const Navbar = () => {
    const [color, setColor] = useState('transparent');
    const matches = useMediaQuery('(max-width:900px)');

    window.onscroll = function () {
        // pageYOffset or scrollY
        if (window.scrollY > 20) {
            setColor('#000000');
        } else {
            setColor('transparent');
        }
    };
    return (
        <>
            <Box
                pt={{ xs: 1, sm: 1, md: 1 }}
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    bgcolor: color,
                    zIndex: 100,
                    //  matches ? '#000000' : 'transparent',
                }}
            >
                <Container maxWidth="xl">
                    <Stack direction="row" justifyContent={'space-between'}>
                        <Stack direction="row" alignItems={'center'}>
                            <img
                                src={Logo}
                                alt=""
                                style={{
                                    width: matches ? '40px' : '60px ',
                                }}
                            />

                            <img
                                src={LogoName}
                                alt=""
                                height="30px"
                                style={{
                                    height: matches ? '20px' : '30px ',
                                }}
                            />
                        </Stack>
                        <Stack
                            direction="row"
                            gap={{ xs: 1, sm: 2 }}
                            display="flex"
                            alignItems="center"
                        >
                            {/* <Box>Connecting with us ...</Box> */}
                            <a href="">
                                <img src={Twitter} alt="" width={'20px'} />
                            </a>
                            <a href="">
                                <img src={Telegram} alt="" width={'20px'} />
                            </a>
                            <a href="">
                                <img src={Discord} alt="" width={'20px'} />
                            </a>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
        </>
    );
};

export default Navbar;
