import React, { useMemo, useState } from 'react';
import { responsiveFontSizes } from '@mui/material/styles';
import { Box, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Routes } from 'react-router-dom';

import BG from './assets/BG.png';

import { createCustomTheme } from './utils/createCustomTheme';
import Home from './pages/Home';
// import ClubPass from './pages/ClubPass';
// import RaceEarn from './pages/RaceEarn';

const App = () => {
    const [mode, setMode] = useState('dark');

    const toggleMode = () => {
        setMode((val) => (val === 'light' ? 'dark' : 'light'));
    };

    const theme = useMemo(() => {
        let theme = createCustomTheme(mode);
        theme = responsiveFontSizes(theme);
        return theme;
    }, [mode]);

    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline enableColorScheme />
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    limit={3}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme={mode}
                />
                <Routes>
                    <Route
                        path="/"
                        element={
                            <Box
                                sx={{
                                    backgroundImage: `url(${BG})`,
                                    backgroundPosition: 'center center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    zIndex: -100000,
                                }}
                            >
                                <Home toggleMode={toggleMode} />
                                {/* <ClubPass />
                                <RaceEarn /> */}
                            </Box>
                        }
                    />
                </Routes>
            </ThemeProvider>
        </>
    );
};

export default App;
